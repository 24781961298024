<template>
  <v-card class="pa-4" flat>
    <v-tabs v-model="tab" center-active @change="tabValue">
      <v-tab>
        {{ $t("absent.absent_per_day") }}
      </v-tab>
      <v-tab>
        {{ $t("absent.absent_per_subjects") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <Student />
      </v-tab-item>
      <v-tab-item>
        <Header
          :idClass="idClass"
          :idGrade="idGrade"
          :idSchoolYear="idSchoolYear"
          :itemTeacher="itemTeacher"
          class="mt-5"
          @setIdClass="newValue => (idClass = newValue)"
          @setIdGrade="newValue => (idGrade = newValue)"
          @setIdSchoolYear="newValue => (idSchoolYear = newValue)"
          @setTeacher="newValue => (itemTeacher = newValue)"
        />
        <Table
          :idClass="idClass"
          :idGrade="idGrade"
          :idSchoolYear="idSchoolYear"
          :isInitData="initData"
          :itemTeacher="itemTeacher"
          @changeInitData="newValue => (initData = newValue)"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
export default {
  components: {
    Student: () => import("./Student"),
    Header: () => import("./components/absentPerSubjects/Header"),
    Table: () => import("./components/absentPerSubjects/Table")
  },
  watch: {
    "$route.query.tab"(n) {
      if (n) this.tab = this.$route.query.tab;
    }
  },
  data() {
    return {
      tab: this.$route.query.tab | 0,
      idClass: null,
      idGrade: null,
      idSchoolYear: null,
      itemTeacher: [],
      initData: false
    };
  },
  methods: {
    tabValue(tab) {
      this.$router.push({ name: "Absent", query: { tab: tab } });
    }
  }
};
</script>
